// @flow
import * as React from 'react';
import classnames from 'classnames';
import Icon from '../../_particles/icon/Icon.jsx';

type Props = {
  children?: React.Node,
}

type State = {
  collapsed: boolean,
}

export default class Collapsable extends React.Component<Props, State> {
  state = {
    collapsed: true,
  };

  toggle() {
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  }

  open() {
    this.setState({ collapsed: false });
  }

  render() {
    const { collapsed } = this.state;
    const className = classnames({
      'list-map__collapse': true,
      'list-map__collapse--open': !collapsed,
    });

    const attributes = {
      onClick: collapsed ? this.open.bind(this) : undefined,
      role: collapsed ? 'button' : undefined,
    };

    return (
      <div className={className} {...attributes}>
        {this.props.children}
        <button className='list-map__collapse-bar' type='button' onClick={this.toggle.bind(this)}>
          <Icon icon={collapsed ? 'caret-down' : 'caret-up'} />
        </button>
      </div>
    );
  }
}
