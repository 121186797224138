import React, { Component } from 'react';
import ReactMapBoxGl, { Marker, Cluster, ZoomControl } from 'react-mapbox-gl';
import { groupBy, values } from 'lodash';
import { encode } from 'latlon-geohash';
import appendQuery from 'append-query';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import memoize from 'fast-memoize';
import { MAPBOX_KEY } from 'javascripts/constants';
import ClusterMarker from './marker/ClusterMarker';
import MapMarker from './marker/MapMarker';
import MarkerPopup from './popup/MarkerPopup';

import { lngLatLikeToArray, getBboxForPointWithBuffer, getBboxForPositions } from './helpers';

function getClusters(items) {
  return values(groupBy(items, 'geoHash')).map(cluster => ({
    position: cluster[0].position,
    geoHash: cluster[0].geoHash,
    items: cluster,
  }));
}

const getClustersMemoized = memoize(getClusters);

// Init react-mapbox-gl
const MapboxGLMap = ReactMapBoxGl({
  accessToken: MAPBOX_KEY,
  injectCss: false,
  scrollZoom: true,
  preserveDrawingBuffer: true,
});

// Map component
export default class Map extends Component {
  constructor(props) {
    super(props);

    this.handleMutation = this.handleMutation.bind(this);
  }

  state = {
    items: [],
    selectedItem: null,
    multiPinIndex: null,
  };

  handleMutation(mutation) {
    if (!mutation || !mutation.target || !mutation.target.textContent) {
      return;
    }

    const { target: { textContent } } = mutation;

    const newConfig = JSON.parse(textContent);
    const { listApi: newListApi } = newConfig;

    this.fetchData(newListApi, this.fitBounds);
  }

  watchForConfigChanges() {
    // zu überwachende Zielnode (target) auswählen
    const $configuration = document.querySelector('.list-map__configuration');

    // eine Instanz des Observers erzeugen
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach(this.handleMutation);
    });

    // Konfiguration des Observers: alles melden - Änderungen an Daten, Kindelementen und Attributen
    const config = {
      attributes: true, childList: true, characterData: true, subtree: true,
    };

    // eigentliche Observierung starten und Zielnode und Konfiguration übergeben
    this.observer.observe($configuration, config);
  }

  componentDidMount() {
    const { listApi } = this.props;

    this.fetchData(listApi);

    this.watchForConfigChanges();
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  fetchData(api, cb) {
    // Fetch items
    fetch(api)
      .then(data => data.json())
      .then((items) => {
        const itemsWithGeoHash = items.map((item) => {
          const { lng, lat } = item.position;
          return { ...item, geoHash: encode(lat, lng) };
        });

        this.setState({ items: itemsWithGeoHash }, cb);
      });
  }

  onPopupCloseClick() {
    this.setState({ selectedItem: null, multiPinIndex: null });
  }

  async getSelectedItemWithDetails(selectedItem) {
    const { detailApi } = this.props;

    const detailRequests = selectedItem.items.map(item => fetch(
      appendQuery(detailApi, {
        tx_rsmdsdsh_id: item.id,
      }),
    ).then(data => data.json()));

    const items = await Promise.all(detailRequests);

    return {
      ...selectedItem,
      items,
    };
  }

  async onMapMarkerClick(event, selectedItem) {
    const selectedItemWithDetails = await this.getSelectedItemWithDetails(selectedItem);

    this.setState({ selectedItem: selectedItemWithDetails, multiPinIndex: null }, () => {
      const position = this.map.project(selectedItem.position);
      const lngLat = this.map.unproject({
        x: position.x,
        y: position.y - 100,
      });

      this.map.panTo(lngLat, {
        animate: true,
        duration: 500,
      });
    });
  }

  onRender(map) {
    // Set language to German
    map.addControl(
      new MapboxLanguage({
        defaultLanguage: 'de',
      }),
    );
  }

  onStyleLoad(map) {
    // Save reference to map
    this.map = map;

    this.fitBounds();
  }

  fitBounds() {
    const { center } = this.props;
    const { items } = this.state;

    // Get bounds
    const bounds = center
      ? getBboxForPointWithBuffer(center, 5)
      : getBboxForPositions(items.map(item => item.position));

    // Set bounds
    this.map.fitBounds(bounds, {
      padding: center ? 0 : 100,
      animate: false,
      duration: 0,
    });
  }

  renderClusterMarker(position, pointCount) {
    return (
      <Marker key={`${position[0]}-${position[1]}`} coordinates={position}>
        <ClusterMarker pointCount={pointCount} />
      </Marker>
    );
  }

  renderCluster() {
    const { items, multiPinIndex, selectedItem } = this.state;

    const clusters = getClustersMemoized(items);

    return (
      <Cluster
        ClusterMarkerFactory={this.renderClusterMarker}
        zoomOnClick
        zoomOnClickOptions={{ padding: 20 }}
      >
        {clusters.map((cluster) => {
          const activeMarkerInCluster = (selectedItem && multiPinIndex)
            && selectedItem.geoHash === cluster.geoHash
            ? multiPinIndex
            : 0;

          return (
            <Marker key={cluster.geoHash} coordinates={lngLatLikeToArray(cluster.position)}>
              <MapMarker
                onClick={e => this.onMapMarkerClick(e, cluster)}
                color={cluster.items[activeMarkerInCluster].property.markerColor}
              />
            </Marker>
          );
        })}
      </Cluster>
    );
  }

  handlePageChange(multiPinIndex) {
    this.setState({ multiPinIndex });
  }

  renderPopup() {
    if (!this.state.selectedItem) {
      return null;
    }

    const { selectedItem } = this.state;

    return (
      <MarkerPopup
        coordinates={lngLatLikeToArray(selectedItem.position)}
        items={selectedItem.items}
        onCloseButtonClick={this.onPopupCloseClick.bind(this)}
        onPageChange={this.handlePageChange.bind(this)}
      />
    );
  }

  render() {
    const { items } = this.state;

    if (!items.length) {
      return null;
    }

    return (
      <MapboxGLMap
        style="mapbox://styles/mapbox/streets-v10"
        className="list-map__map"
        movingMethod="easeTo"
        onStyleLoad={this.onStyleLoad.bind(this)}
        onRender={this.onRender.bind(this)}
      >
        {this.renderCluster()}
        {this.renderPopup()}
        <ZoomControl position="top-right" className="list-map__zoom-control" />
      </MapboxGLMap>
    );
  }
}
