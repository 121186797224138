import React, { Component } from 'react';
import paginate from 'paginate-array';
import Pagination from '../../../molecules/pagination/Pagination';
import PopupItem from './PopupItem';

export default class PopupList extends Component {
  static defaultProps = {
    perPage: 1,
  };

  state = {
    currentPage: 1,
  }

  onPageChange(newPage) {
    this.props.onPageChange(newPage - 1);

    this.setState({ currentPage: newPage });
  }

  render() {
    const { items, perPage } = this.props;
    const { currentPage } = this.state;
    const paginated = paginate(items, currentPage, perPage);

    return (
      <div className='list-map__list'>
        {paginated.data.map(item => <PopupItem key={item.id} {...item} />)}

        <div className='list-map__pagniation'>
          {paginated.totalPages > 1 && <Pagination
            currentPage={paginated.currentPage}
            lastPage={paginated.totalPages}
            onPageChange={this.onPageChange.bind(this)}
            small={true}
          />}
        </div>
      </div>
    );
  }
}
