import React from 'react';
import MoreLink from '../../../atoms/more-link/MoreLink';

const PopupItem = ({
  name, nameAddition, street, houseNumber, city, postalCode, link,
}) => {
  const address = [`${street} ${houseNumber}`, `${postalCode} ${city}`];

  return (
    <div className="list-map__tooltip">
      {name && <h4 className="headline headline--6">{name}</h4>}

      {nameAddition && (
        <div className="list-map__tooltip-detail">
          <div className="text text--small">{nameAddition}</div>
        </div>
      )}

      <div className="list-map__tooltip-detail">
        <div className="text text--small">
          {address[0] && address[0]} <br />
          {address[1] && address[1]}
        </div>
      </div>

      {link && (
        <div className="list-map__tooltip-link">
          <MoreLink link={link}>Mehr erfahren</MoreLink>
        </div>
      )}
    </div>
  );
};

export default PopupItem;
