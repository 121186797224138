/* @flow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';

const currentPageText = 'Aktuelle Seite: ';
const otherPageText = 'Zur Seite ';

type Props = {
  currentPage: number,
  lastPage: number,
  small: boolean,

  onPageChange: (number) => void,
};

export default class Pagination extends Component<Props> {
  static defaultProps = {
    onPageChange: () => {},
  };

  handleLinkClick = (i: number) => {
    this.props.onPageChange(i);
  }

  handlePrevPageClick = () => {
    this.props.onPageChange(this.props.currentPage - 1);
  }

  handleNextPageClick = () => {
    this.props.onPageChange(this.props.currentPage + 1);
  }

  paginationItemCurrentPage = (i: number) => (
    <li key={i} className="pagination__page pagination__page--current t-inverted">
      <a className="link link--internal" aria-current="page">
        <span className="u-hidden-visually">{currentPageText}</span>{i}
      </a>
    </li>
  )

  paginationItemOtherPage = (i: number) => (
    <li key={i} className="pagination__page">
      <a className="link link--internal" onClick={() => this.handleLinkClick(i)}>
        <span className="u-hidden-visually">{otherPageText}</span>{i}
      </a>
    </li>
  )

  buildPaginationItems = (currentPage: number, lastPage: number) => {
    const paginationItems = [this.paginationItemCurrentPage(currentPage)];

    let prevPages = 0;
    let nextPages = 0;
    // add previous two pages
    if ((currentPage - 1) >= 1) {
      paginationItems.unshift(this.paginationItemOtherPage(currentPage - 1));
      prevPages += 1;
    }
    if ((currentPage - 2) >= 1) {
      paginationItems.unshift(this.paginationItemOtherPage(currentPage - 2));
      prevPages += 1;
    }
    // fill with next pages
    while (paginationItems.length < 5) {
      if ((currentPage + nextPages) === lastPage) {
        break;
      }
      paginationItems.push(this.paginationItemOtherPage(currentPage + nextPages + 1));
      nextPages += 1;
    }
    // fill with previous pages if space left
    while (paginationItems.length < 5) {
      if ((currentPage - prevPages - 1) === 0) {
        break;
      }
      paginationItems.unshift(this.paginationItemOtherPage(currentPage - prevPages - 1));
      prevPages += 1;
    }

    return paginationItems;
  }

  render() {
    const { currentPage } = this.props;
    let { lastPage } = this.props;

    if (lastPage === 0) {
      lastPage = 1;
    }

    const paginationItems = this.buildPaginationItems(currentPage, lastPage);

    return (
      <div>
        <nav className='pagination' aria-label="Seitennummerierung">
          <ol className="pagination__pages">
            {paginationItems}
          </ol>
        </nav>
      </div>
    );
  }
}
