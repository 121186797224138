import React from 'react';
import classnames from 'classnames';
import Icon from '../../_particles/icon/Icon.jsx';

const MoreLink = ({
  tag, up = false, link, type, external = false, download = false, children,
}) => {
  const Tag = (link && 'a') || tag || 'span';
  const classNames = classnames({
    'more-link': true,
    'more-link--up': up,
  });

  return (
    <Tag
      className={classNames}
      href={link}
      type={type}
      rel={external ? 'noopener noreferrer' : undefined}
      target={(external || download) ? '_blank' : undefined}
      download={download}
    >
      <Icon icon="angle-right" viewBox="0 0 120 200" className="more-link__icon" />
      <span className="more-link__text">{children}</span>
    </Tag>
  );
};

export default MoreLink;
