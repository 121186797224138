import React from 'react';
import { Popup } from 'react-mapbox-gl';
import PopupList from './PopupList';
import { t } from '../../../../javascripts/utils/withTranslation';

const MarkerPopup = ({
  coordinates, items, onCloseButtonClick, onPageChange,
}) => (
  <Popup anchor="bottom" className="list-map__popup" offset={[0, -30]} coordinates={coordinates}>
    <button
      className="mapboxgl-popup-close-button"
      type="button"
      aria-label={t('Popup schließen')}
      onClick={onCloseButtonClick}
    >
      ×
    </button>
    <PopupList key={coordinates} items={items} onPageChange={onPageChange} />
  </Popup>
);

export default MarkerPopup;
