import { buffer, bbox } from '@turf/turf';
import { point, multiPoint } from '@turf/helpers';

export const lngLatLikeToArray = lngLatLike => [lngLatLike.lng, lngLatLike.lat];

export const bboxToLngLatBoundsLike = bboxArray => [
  [bboxArray[0], bboxArray[1]], [bboxArray[2], bboxArray[3]],
];

export const getBboxForPointWithBuffer = (position, bufferSteps = 2, units = 'kilometers') => {
  const lngLat = lngLatLikeToArray(position);
  const latLngPoint = point(lngLat);
  const pointWithBuffer = buffer(latLngPoint, bufferSteps, { units });
  return bboxToLngLatBoundsLike(bbox(pointWithBuffer));
};

export const getBboxForPositions = (positions) => {
  const lngLats = positions.map(position => lngLatLikeToArray(position));
  const points = multiPoint(lngLats);
  return bboxToLngLatBoundsLike(bbox(points));
};
