// @flow
import * as React from 'react';
import classnames from 'classnames';
import randomId from '../../../javascripts/utils/random-id';

type Props = {
  icon: string,
  title?: string,
  desc?: string,
  viewBox?: string,
  className?: string,
  id?: string,
  paths?: Array<string>,
};

export default (props: Props) => {
  const {
    id, icon, title, desc, className, viewBox = '0 0 200 200', ...rest
  } = props;

  const classes = classnames(
    {
      icon: true,
      [`icon--${icon}`]: true,
    },
    className,
  );

  const titleId = title && `${id || randomId()}-title`;
  const descId = desc && `${id || randomId()}-desc`;
  const ariaLabelledBy = (titleId || descId) && [titleId, descId].join(' ');

  const attributes = {
    id,
    viewBox,
    'aria-labelledby': ariaLabelledBy,
    role: title ? 'img' : 'presentation',
  };

  return (
    <svg className={classes} {...attributes} {...rest}>
      {title && <title title={titleId}>{title}</title>}
      {desc && <desc title={descId}>{desc}</desc>}
      <use xlinkHref={`#icon-${icon}`} />
    </svg>
  );
};
