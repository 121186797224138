import React from 'react';
import ReactDOM from 'react-dom';
import h from 'hyperscript';
import Collapsable from './Collapsable';
import Map from './Map';

const render = ($listMap, configuration = {}) => {
  // Get configuration
  const { listApi, detailApi, center } = configuration;

  // Create mount point
  const $map = h('.list-map__inner');
  $listMap.appendChild($map);

  // Render map
  ReactDOM.render((
    <Collapsable>
      <Map center={center} listApi={listApi} detailApi={detailApi} />
    </Collapsable>
  ), $map);
};

export default render;
